.container-fluid {
    padding-top: 1vh;
    padding-bottom: 1vh;
}
.page-header {
    border: none;
}
.admin-footer {
    bottom: 0px !important;
    position: relative !important;
    width: 99% !important;
    background-color: #f1f1f1 !important;
    padding: 1.25rem 0;
    font-size: 14px;
}

.page .section-body:nth-child(2){
    background-color: #f1f1f1 !important;
    height: 100% !important;
    margin-top: 0px !important;
}
.page .section-body:nth-child(3){
    background-color: #f1f1f1 !important;
    height: 100% !important;
    margin-top: 0px !important;
}
/* .section-body .card {
	min-height: 62vh;
}  */

.spinner_main_div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}