.toggleLabel {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    top: 3px;
}

.toggleInput {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleSpan {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: #2c3e50; */
    background: #004583;
    transition: 0.3s;
    border-radius: 30px;
}

.toggleStrong {
    position: absolute;
    left: 100%;
    width: max-content;
    height: 100%;
    line-height: 30px;
    margin-left: 10px;
    cursor: pointer;
}

.toggleSpan:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.toggleInput:checked + span {
    /* background-color: #00c853; */
    background-color: #004583;

}

.toggleInput:checked + span:before {
    transform: translateX(18px);
}