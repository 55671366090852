.invalidDataError {
  color: red !important;
  text-transform: capitalize;
  font-size: 10px;
}

.headerContainer {
  display: flex;
  /* justify-content: space-between; */
  text-align: left;
  background-color: #f2f2f2;
  font-size: 14px;
  text-transform: uppercase;
}

.textContainer1 {
  width: 25%;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
  border-left: 1px solid #ddd;
  white-space: nowrap;
}

.textContainer2 {
  width: 50%;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
  border-left: 1px solid #ddd;
  white-space: nowrap;
}

.contentContainer{
  display: flex;
  /* justify-content: space-between; */
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
}

.contentText1{
    width: 25%;
    border-left: 1px solid #ddd;
}

.contentText2{
    width: 50%;
    border-left: 1px solid #ddd;
}


.contentList1{
    padding: 0.75rem 0.5rem; 
}


.contentList2{
    padding: 0.75rem 0.5rem; 
}

.contentList1:hover{
    background-color: #ececec;
    cursor: pointer
}

.employeeContainer{
  display: flex;
  justify-content: space-between;
}


@media(max-width:768px){
 

  .headerContainer{
    width: 800px;
  }

  .contentContainer{
    width: 800px;
  }

}


